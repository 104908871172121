import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from "../../components/layout/Layout";
import Seo from "../../components/seo";
import VideoWrapper from "../../components/VideoWrapper";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
  <Seo title="Pre-Procedure Instructions - pikespeakoralsurgery" description="Please review these important pre-operative instructions before your oral surgery procedure" mdxType="Seo" />
  <section className="container" style={{
        margin: '128px auto'
      }}>
    <VideoWrapper video="LFcr-saSi88" centered mdxType="VideoWrapper" />
        <h2>{`INSTRUCTIONS FOR THE DAY OF SURGERY`}</h2>
        <ul>
          <li parentName="ul">{`Have nothing to eat or drink for a minimum of 8 hours prior to your surgery, including water.`}</li>
          <li parentName="ul">{`Unless you are told otherwise by your physician or surgeon, any medication you normally use should be taken with a small sip of water according to your daily routine.`}</li>
          <li parentName="ul">{`No alcohol or marijuana use 24 hours before surgery.`}</li>
          <li parentName="ul">{`No contact lenses.`}</li>
          <li parentName="ul">{`You will need a responsible adult driver with you who will stay in our office during your entire procedure. No taxis. You will not be able to drive or operate heavy machinery for the first 24 hours following your procedure.`}</li>
          <li parentName="ul">{`You will need to arrive 10 minutes prior to your surgery time. If you arrive late to your appointed time, you may be rescheduled at our discretion.`}</li>
          <li parentName="ul">{`Any insurance changes or questions must be handled prior to the surgery date. Please contact our office with any changes or questions, and we will be more than happy to help you. Insurance issues will not be handled the day of surgery.`}</li>
          <li parentName="ul">{`Please notify us at least 24 hours prior to your surgery if you need to cancel or reschedule your appointment. There will be a $75 service charge for less than 24-hours’ notice of surgery cancellations or rescheduled appointments.`}</li>
          <li parentName="ul">{`The day before your surgery, we will call in your pain medication to the pharmacy you provided. This will allow you to pick up your medication in advance so that you may have it readily available following your procedure.`}</li>
        </ul>
      </section>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      